<template>
  <div class="news-search">
    <div class="search">
      <a-card :bordered="false">
        <img slot="cover" alt="example" src="~@/assets/images/pic.jpg" />
      </a-card>
      <div class="search-con">
        <a-input-group compact>
          <a-select default-value="1" @change="getChange" dropdownClassName="select-drop-down-name">
            <a-select-option value="1"> 新闻 </a-select-option>
            <a-select-option value="2"> 图书 </a-select-option>
          </a-select>
          <a-input-search
            placeholder="请输入关键词"
            enter-button="检索"
            v-model="searchValue"
            @search="getList"
          />
        </a-input-group>
      </div>
    </div>
    <div class="list-con">
      <div class="container">
        <a-list :grid="{ column: 1 }" :data-source="dataNewsList">
          <template slot="header">
            检索到<span>{{ searchValueNow }}</span
            >相关新闻{{total}}条，共{{ pageCount }}页，每页20条
          </template>
          <a-list-item slot="renderItem" slot-scope="item">
            <a-card :bordered="false">
              <template slot="title">
                <i></i>
                <a @click="goDetails(item.id)" v-html="item.newTitle"></a>
              </template>
              <template slot="extra">{{ item.pubdate }}</template>
            </a-card>
          </a-list-item>
        </a-list>
        <a-pagination
          show-quick-jumper
          v-if="total > 20"
          v-model="current"
          :total="total"
          :pageSize="20"
          @change="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/newsList.js";
import { replaceNew } from "@/utils/util";
export default {
  name: "BookList",
  components: {},
  data() {
    return {
      searchValue: "",
      dataNewsList: [],
      current: 1,
      total: 0,
      pageCount: 0,
      searchValueNow: "",
    };
  },
  created() {
    if (this.$route.params && this.$route.params.value) {
      this.searchValue = this.$route.params.value;
    }
    //查询新闻
    JSON.stringify;
    this.getList();
  },
  methods: {
    //查看新闻详情
    goDetails(doi) {
      this.$router.push({ name: "NewsDetail", params: { doi: doi } });
    },
    getList() {
      let obj = {
        pageSize: 20,
        pageNumber: this.current,
        searchValue: this.searchValue,
      };
      this.searchValueNow = this.searchValue;
      let vm = this;
      api.getList(obj).then((res) => {
        if (res.data.success) {
          this.dataNewsList = res.data.data.list;
          this.dataNewsList.map(function (item) {
            item.newTitle = replaceNew(item.title, vm.searchValue);
          });
          document.documentElement.scrollTop = 0;
          this.total = res.data.data.count;
          this.pageCount =
            this.total % 20 === 0
              ? this.total / 20
              : Math.floor(this.total / 20) + 1;
        } else {
          this.dataNewsList = [];
        }
      });
    },
    getChange(key) {
      if (key === '2') {
        this.$router.push({ name: "BookList",params:{value:this.searchValue} });
      }
    },
    onChange(pageNumber) {
      this.current = pageNumber;
      this.getList();
    },
  },
};
</script>
<style lang="less">
@import "./NewsSearch.less";
</style>